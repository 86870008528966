<template>
  <v-main>
    <v-container>
      <v-card class="contactCard">
        <v-row
          no-gutters
          align="end"
          style="opacity: 1; position: absolute; z-index: 50"
          :style="
            $vuetify.breakpoint.xs
              ? 'margin: 10px;padding-left: 15px;'
              : 'margin: 10px;padding-left: 35px;'
          "
        >
          <v-col cols="12" xl="9" lg="6" md="6" sm="12" class="white--text">
            <span class="componentTitle">{{ "our_contact" | localize }}</span>
            <p class="contact_text">
              {{ "our_contact_description" | localize }}
            </p>
            <v-row no-gutters align="start" style="margin-top: 40px">
              <v-col cols="12" xl="6" lg="6" md="6" sm="12" :style="$vuetify.breakpoint.sm ? 'margin-bottom: 20px;' : ''">
                <p class="sectionSubtitle" style="color: #fea31e">{{ "label_phone" | localize }}</p>
                <span class="contact_item" id="phone_number_1">
                  <a
                    style="text-decoration: none; color: white; cursor: pointer"
                    href="tel: +380774477047"
                    >+38 (077) 447 70 47 <br /> </a
                ></span>
                 <span class="contact_item" id="phone_number_2">
                </span>
              </v-col>
              <v-col
                cols="12"
                xl="6"
                lg="6"
                md="6"
                sm="12"
                :style="$vuetify.breakpoint.xs ? 'margin-top: 30px;' : ''"
              >
                <p class="sectionSubtitle" style="color: #fea31e">
                  {{ "label_write_to_us" | localize }}
                </p>
                <p class="contact_item">
                  <a
                    style="text-decoration: none; color: white; cursor: pointer"
                    href="mailto: info@bmax.com.ua"
                    >{{ "our_contact_mail" | localize }}</a
                  >
                </p>
              </v-col>
            </v-row>
            <p class="sectionSubtitle" style="margin-top: 30px; color: #fea31e;">
              {{ "label_visit_us" | localize }}
            </p>
            <p class="contact_item"><a style="text-decoration: none; color: white;cursor: pointer;" href="https://goo.gl/maps/gHWU6gPhLUwUaFS16" target="_blank">{{ "our_contact_placement" | localize }}</a></p>
						<button class="contact-btn"  @click="$emit('showContactForm')"> 
							<div class="contact-btn-text">{{"main_btn" | localize}}</div>
							<v-icon size="22"> mdi-arrow-top-right </v-icon>
						</button>
          </v-col>
        </v-row>
        <v-row no-gutters justify="end">
          <div class="backgroundImg"></div>
        </v-row>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
export default {

};
</script>

<style scoped>
.contact-btn {
	background: #FEA31E;
	display: flex;
	align-items: center;
	max-width: 380px;
	width: 100%;
	min-height: 50px;
	border-radius:20px;
	padding: 4px 24px; 
	transition: all .5s;
	justify-content: flex-end;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-top: 30px;
}
.contact-btn:hover{
	background: #BA6324;
}
.contact-btn:hover .contact-btn-text{
	color: #FFF;
}
.contact-btn:hover i{
	color: #FFF;
}
.contact-btn-text {
	font-size: 20px;
	line-height: 22px;
	color: #232323;
	transition: all .5s;
	padding: 12px;
	width: 92%;
}
@media only screen and (max-width: 609px) {
  .contact_text {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #ffffff;
  }
  .sectionSubtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    margin-bottom: 15px;
    color: #ffffff;
  }
  .contact_item {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-transform: uppercase;
    color: #ffffff;
  }
  .contactCard {
    background-color: #0d0d0d !important;
    height: 614px;
    width: 100%;
    border-radius: 30px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .backgroundImg {
    background-image: url("../../assets/img/contactBackgroundImg.svg");
    opacity: 0.5;
    width: 100%;
    height: 252px;
    background-size: cover;
  }
}
@media only screen and (min-width: 610px) {
  .contact_text {
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    text-transform: uppercase;
    color: #C7C7C7;
    margin-bottom: 0px;
		margin-top: 12px;
  }
  .sectionSubtitle {
    font-weight: 400;
    font-size: 20px;
    line-height: 15px;
    text-transform: uppercase;
    color: #FEA31E;
    margin-bottom: 12px;
  }
  .contact_item {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-transform: uppercase;
    color: #ffffff;
  }
  .contactCard {
    background-color: #0d0d0d !important;
    width: 100%;
    padding: 50px 100px 20px 20px;
    border-radius: 30px !important;
  }
  .backgroundImg {
    background-image: url("../../assets/img/contactBackgroundImg.svg");
    opacity: 0.5;
    width: 528px;
    height: 500px;
  }
}
@media(max-width: 400px)
{
	.contact-btn-text{
		font-size: 16px;
		line-height: 18px;
	}
}
</style>