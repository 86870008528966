<template>
  <v-col>
    <v-row no-gutters align="center">
      <v-col cols="12" sm="6" md="6" lg="6" xl="6">
        <v-text-field
          class="rounded-pill"
          :style="$vuetify.breakpoint.xs ? '' : 'padding-right: 10px'"
          dense
          outlined
          :label="
            user.locale == 'ua'
              ? examples.name_ua
              : user.locale == 'en'
              ? examples.name_en
              : examples.name_de
          "
          color="#0D0D0D"
          v-model="userV.name"
          :class="
            nameError.length
              ? 'error_text-field'
              : !$v.userV.name.$invalid && userV.name
              ? 'success_text-field'
              : 'text_field'
          "
          :error-messages="nameError"
        />
      </v-col>
      <v-col cols="12" sm="6" md="6" lg="6" xl="6">
        <v-text-field
          class="rounded-pill"
          :style="$vuetify.breakpoint.xs ? '' : 'padding-left: 10px'"
          dense
          outlined
          :label="
            user.locale == 'ua'
              ? examples.phone_ua
              : user.locale == 'en'
              ? examples.phone_en
              : examples.phone_de
          "
          v-mask="'+##################'"
          color="#0D0D0D"
          v-model="userV.phone_number"
          :class="
            phoneNumberError.length
              ? 'error_text-field'
              : !$v.userV.phone_number.$invalid && userV.phone_number
              ? 'success_text-field'
              : 'text_field'
          "
          :error-messages="phoneNumberError"
        />
      </v-col>
    </v-row>
    <v-row no-gutters align="center">
      <v-col cols="12" sm="6" md="6" lg="6" xl="6">
        <v-text-field
          class="rounded-pill"
          :style="$vuetify.breakpoint.xs ? '' : 'padding-right: 10px'"
          dense
          outlined
          :label="
            user.locale === 'ua'
              ? examples.mail_ua
              : user.locale === 'en'
              ? examples.mail_en
              : examples.mail_de
          "
          color="#0D0D0D"
          v-model="userV.email"
          :class="
            emailError.length
              ? 'error_text-field'
              : !$v.userV.email.$invalid && userV.email
              ? 'success_text-field'
              : 'text_field'
          "
          :error-messages="emailError"
        />
      </v-col>
      <v-col cols="12" sm="6" md="6" lg="6" xl="6">
        <v-text-field
          class="rounded-pill"
          :style="$vuetify.breakpoint.xs ? '' : 'padding-left: 10px'"
          dense
          outlined
          :label="
            user.locale === 'ua'
              ? examples.company_ua
              : user.locale === 'en'
              ? examples.company_en
              : examples.company_de
          "
          color="#0D0D0D"
          v-model="userV.company_name"
          :class="
            companyNameError.length
              ? 'error_text-field'
              : !$v.userV.company_name.$invalid && userV.company_name
              ? 'success_text-field'
              : 'text_field'
          "
          :error-messages="companyNameError"
        />
      </v-col>
    </v-row>
    <v-textarea
      style="border-radius: 20px"
      dense
      outlined
      :label="
        user.locale === 'ua'
          ? examples.text_ua
          : user.locale === 'en'
          ? examples.text_en
          : examples.text_de
      "
      color="#0D0D0D"
      v-model="userV.project_description"
      rows="3"
      no-resize
    />
    <v-row no-gutters :justify="$vuetify.breakpoint.xs ? 'center' : 'end'">
      <v-btn
        :width="$vuetify.breakpoint.xs ? '100%' : '220px'"
        height="45px"
        class="main_btn"
        style="margin-top: 10px"
        @click="sendMessage"
      >
        <v-row justify="center" no-gutters align="center">
          {{ "btn_send" | localize }}
          <v-icon small style="margin-left: 10px"> mdi-arrow-top-right </v-icon>
        </v-row>
      </v-btn>
    </v-row>
  </v-col>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import {
  required,
  email,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  data: () => ({
    userV: {},
    examples: {
      name_ua: "Ваше ім’я",
      phone_ua: "Номер телефону",
      mail_ua: "Ваш Email",
      company_ua: "Назва компанії",
      text_ua: "Опишіть ваш проєкт",
      name_en: "Your name",
      phone_en: "Phone number",
      mail_en: "Your Email",
      company_en: "The company name",
      text_en: "Describe your project",
      name_de: "Name",
      phone_de: "Telefonnummer",
      mail_de: "E-Mail",
      company_de: "Firmenname",
      text_de: "Beschreiben Sie Ihr Projekt",
    },
  }),
  validations: {
    userV: {
      name: {
        required,
      },
      phone_number: {
        required,
        minLength: minLength(13),
        maxLength: maxLength(18),
      },
      email: {
        required,
        email,
      },
      company_name: {
        required,
      },
    },
  },
  methods: {
    sendMessage() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        console.log(this.userV);
        this.$emit("successSendMessage");
      }
    },
  },
  computed: {
    nameError() {
      const errors = [];
      if (!this.$v.userV.name.$dirty) {
        return errors;
      }
      !this.$v.userV.name.required &&
        errors.push(
          this.user.locale == "ua"
            ? "Обов’язкове поле"
            : this.user.locale == "en"
            ? "Mandatory field"
            : "Pflichtfeld"
        );
      return errors;
    },
    phoneNumberError() {
      const errors = [];
      if (!this.$v.userV.phone_number.$dirty) {
        return errors;
      } else if (!this.$v.userV.phone_number.required) {
        errors.push(
          this.user.locale == "ua"
            ? "Обов’язкове поле"
            : this.user.locale == "en"
            ? "Mandatory field"
            : "Pflichtfeld"
        );
        return errors;
      }
      if (
        !this.$v.userV.phone_number.minLength ||
        !this.$v.userV.phone_number.maxLength
      ) {
        errors.push(
          this.user.locale == "ua"
            ? "Номер телефону введено некоректно"
            : this.user.locale == "en"
            ? "The phone number was entered incorrectly"
            : "Die Telefonnummer wurde falsch eingegeben"
        );
      }
      return errors;
    },
    emailError() {
      const errors = [];
      if (!this.$v.userV.email.$dirty) {
        return errors;
      }
      !this.$v.userV.email.required &&
        errors.push(
          this.user.locale == "ua"
            ? "Обов’язкове поле"
            : this.user.locale == "en"
            ? "Mandatory field"
            : "Pflichtfeld"
        );
      if (!this.$v.userV.email.email) {
        errors.push(
          this.user.locale == "ua"
            ? "Email введено некоректно"
            : this.user.locale == "en"
            ? "The email was entered incorrectly"
            : "Die E-Mail-Adresse wurde falsch eingegeben"
        );
        return errors;
      }
      return errors;
    },
    companyNameError() {
      const errors = [];
      if (!this.$v.userV.company_name.$dirty) {
        return errors;
      }
      !this.$v.userV.company_name.required &&
        errors.push(
          this.user.locale == "ua"
            ? "Обов’язкове поле"
            : this.user.locale == "en"
            ? "Mandatory field"
            : "Pflichtfeld"
        );
      return errors;
    },
    ...mapGetters(["user"]),
  },
};
</script>

<style>
.v-text-field--outlined .v-label {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #676767;
  padding: 0px 0px 13px 0px;
}
.error_text-field fieldset {
  color: #a10909 !important;
  border-radius: 20px;
}
.error_text-field .error--text {
  color: #a10909 !important;
  caret-color: #a10909 !important;
}
.success_text-field fieldset {
  color: #fea31e !important;
  border-radius: 20px;
}
.text_field {
  color: #0d0d0d !important;
  border-radius: 20px;
}
</style>
