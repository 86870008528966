<template>
  <v-main>
    <v-container>
      <v-col>
        <p class="componentTitle" :style="$vuetify.breakpoint.xl ? 'margin-bottom: 20px' : $vuetify.breakpoint.xs ? 'margin-bottom: 16px;' : 'margin-bottom: 20px'">
          {{ "develop_steps_title" | localize }}
        </p>
        <p class="componentSubtitle">
          {{ "develop_steps_description" | localize }}
        </p>
        <steps-list :style="$vuetify.breakpoint.xl ? 'margin-top: 80px' : $vuetify.breakpoint.xs ? 'margin-top: 40px;' : ''" />
      </v-col>
    </v-container>
  </v-main>
</template>

<script>
import stepsList from "./stepsList.vue";
export default {
  components: { stepsList },
};
</script>

<style scoped>
</style>