<template>
  <v-app>
    <router-view
      @showContactForm="showContactForm = true"
      @showMobileNavigationDrawer="showMobileNavigationDrawer = true"
      :scrollId="scrollId"
    />
    <div v-if="showContactForm">
      <v-overlay style="z-index: 100" absolute> </v-overlay>
      <contact-form-component
        @close="showContactForm = false"
      />
    </div>
    <mobile-navigation-drawer
      v-if="showMobileNavigationDrawer"
      @close="closeMobileDrawer"
      @scrollTo="scrollTo"
    />
    <footer-component />
    <transition name="fade">
      <img
        transition="scroll-y-transition"
        v-scroll="onScroll"
        v-show="top"
        @click="toTop"
        fixed
        class="to-top-btn"
        src="@/assets/img/toTopIcon.svg"
      />
    </transition>
  </v-app>
</template>

<script>
import ContactFormComponent from "./components/contactForm/contactFormComponent.vue";
import footerComponent from "./components/Footer/footerComponent.vue";
import MobileNavigationDrawer from "./components/mobileNavigationDrawer.vue";
import { mapGetters } from "vuex";
export default {
  components: { footerComponent, ContactFormComponent, MobileNavigationDrawer },
  name: "App",
  data: () => ({
    showContactForm: false,
    showMobileNavigationDrawer: false,
    top: false,
    scrollId: "",
  }),
  beforeCreate() {
    document.title = this.$options.filters.localize("main_title");
  },
  methods: {
    closeMobileDrawer() {
      this.showMobileNavigationDrawer = false;
    },
    scrollTo(path) {
      this.scrollId = path;
      this.showMobileNavigationDrawer = false;
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.top = top > 30;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
  },
  computed: {
    ...mapGetters(["user"]),
  },
  watch: {
    "user.locale": {
      deep: true,
      handler() {
        document.title = this.$options.filters.localize("main_title");
      },
    },
  },
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  background-color: black;
  /* overflow-x: hidden; */
  background-image: url("~@/assets/backgroundNew.jpg");
}
.to-top-btn {
  bottom: 70px;
  right: 28px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  z-index: 99;
  position: fixed;
  opacity: 0.4;
	transition: opacity .2s;
}
.to-top-btn:hover {
  opacity: 1;
  cursor: pointer;
}
.toTopBtnText {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  margin-left: 10px;
}
@media(max-width: 500px)
{
  .to-top-btn{
    display: none;
  }
}
</style>
