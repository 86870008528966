<template>
  <v-container>
    <v-row class="company-row">
      <v-col class="company-list">
        <div
          class="icon-container"
          v-for="partner in partners"
          :key="partner.id"
        >
          <img
            :src="require(`../../assets/img/partners/${partner.src}`)"
            :alt="partner.alt"
            style="max-width: 200px;"
          />
        </div>
      </v-col>
      <v-col class="partners-title"
        >{{ "partners_title" | localize }}
        <span class="partners-text" v-if="!$vuetify.breakpoint.smAndDown">
          pleasure to work with
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      partners: [
        {
          id: 0,
          src: "Vivamus_logo.png",
          alt: "Vivamus",
        },
      ],
    };
  },
};
</script>

<style scoped>
.company-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  max-width: 510px;
  width: 100%;
}
.icon-container {
  margin-bottom: 100px;
}
.partners-title {
  color: #0d0d0d;
  font-size: 58px;
  font-weight: 500;
  line-height: 58px;
  text-transform: uppercase;
  margin-left: 100px;
  max-width: 520px;
  width: 100%;
}
.partners-text {
  color: #fafafa;
  font-size: 96px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}
.company-row {
  justify-content: center;
}
.icon-container--last {
  margin-bottom: 0;
}
@media (max-width: 970px) {
  .partners-title {
    margin-left: 20px;
  }
}
@media (max-width: 815px) {
  .partners-title {
    width: 100%;
    max-width: 815px;
  }
  .company-list {
    max-width: 815px;
    width: 100%;
    flex-basis: auto;
  }
  .company-row {
    flex-wrap: wrap-reverse;
  }
  .icon-container {
    padding: 5px;
  }
  .icon-container--last {
    margin-bottom: 80px;
  }
}
@media (max-width: 450px) {
  .icon-container svg {
    max-width: 100px;
    width: 100%;
    height: auto;
  }
  .icon-container {
    margin-bottom: 57px;
  }
  .icon-container--last {
    margin-bottom: 57px;
  }
  .partners-title {
    font-size: 36px;
    line-height: 40px;
    margin-left: 0px;
  }
}
</style>
