import Vue from 'vue'
import App from './App'
import router from './router'
import vuetify from '@/plugins/vuetify'
import './assets/layouts/index.css'
import store from './store';
import localizeFilter from './filters/localize.filter'
import VueMask from 'v-mask'

Vue.config.productionTip = false

Vue.filter('localize', localizeFilter)
Vue.use(VueMask);
new Vue({
  vuetify,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
