<template>
  <div class="social-container">
    <span class="socialName" v-for="social in social" :key="social.id">
      <a
        target="_blank"
        :href="social.href"
        style="text-decoration: none;"
        class="link"
      >
        {{ social.title }}
      </a>
    </span>
  </div>
</template>

<script>
export default {
  data: () => ({
    social: [
      {
        id: 1,
        title: "Facebook",
        href: "https://www.facebook.com/fb.bmax.com.ua",
      },
      {
        id: 2,
        title: "LinkedIn",
        href: "https://www.linkedin.com/company/bmax-company",
      },
      {
        id: 3,
        title: "Telegram",
        href: "https://t.me/+380977291012",
      },
      {
        id: 4,
        title: "Instagram",
        href: "https://www.instagram.com/bmax.com.ua/",
      },
      {
        id: 5,
        title: "Behance",
        href: "https://www.behance.net/beemaxmax/projects",
      },
      {
        id: 6,
        title: "Twitter",
        href: "https://twitter.com/BeeMax_ua",
      },
    ],
  }),
};
</script>

<style scoped>
.socialName {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #0d0d0d;
  cursor: pointer;
	margin-left: 32px;
}
.link{
	color: #0d0d0d;
  transition: all .3s;
}
.link:hover{
	color: #fea31e;
}
@media(max-width: 830px){
	.social-container{
		margin-bottom: 32px;
	}
	.socialName{
		margin-bottom: 24px;
	}
}
@media (max-width: 609px) {
  .socialName {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #0d0d0d;
    cursor: pointer;
  }
}
@media(max-width: 450px)
{
	.socialName
	{
		margin: 0 5px;
	}
	.socialName a{
		margin-bottom: 24px;
	}
}
@media(max-width: 375px){
	.socialName{
		font-size: 12px;
		line-height: 14px;
		padding: 5px;
	}
}
</style>
