var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-main',[_c('v-container',[_c('v-row',{attrs:{"no-gutters":"","align":"start","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","xl":"4","lg":"4","md":"4","sm":"12"}},[_c('p',{staticClass:"componentTitle",staticStyle:{"color":"black"}},[_vm._v(" "+_vm._s(_vm._f("localize")("example_of_our_work"))+" ")]),_c('swiper',{staticClass:"swiper d-block d-md-none",style:({
            '--swiper-navigation-color': '#000000',
          }),attrs:{"options":_vm.swiperMobileOption}},_vm._l((_vm.examples),function(item){return _c('swiper-slide',{key:item.id},[_c('v-row',{attrs:{"no-gutters":"","align":"center","justify":"center"}},[_c('examples-mobile-card',{attrs:{"item":item}})],1)],1)}),1),_c('v-row',{style:(_vm.$vuetify.breakpoint.sm  || _vm.$vuetify.breakpoint.xs  ? 'margin-top: 30px' : 'margin-top: 50px'),attrs:{"no-gutters":"","align":"center","justify":_vm.$vuetify.breakpoint.sm || _vm.$vuetify.breakpoint.xs  ? 'center' : 'start'}},[_c('div',{staticClass:"swiper-button-prev",staticStyle:{"position":"static"}}),_c('div',{staticClass:"swiper-button-next",staticStyle:{"position":"static"},style:(_vm.$vuetify.breakpoint.xs
                ? 'margin-left: 80px'
                : 'margin-left: 150px')})])],1),_c('v-col',{attrs:{"cols":"8"}},[_c('swiper',{staticClass:"swiper d-none d-lg-block",style:({
            '--swiper-navigation-color': '#000000',
          }),attrs:{"options":_vm.swiperOption}},_vm._l((_vm.examples),function(item,index){return _c('swiper-slide',{key:item.id},[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('examples-card',{attrs:{"item":item}}),(index !== 0)?_c('div',{staticStyle:{"border":"1px solid rgba(208, 208, 208, 0.4)","z-index":"80","height":"200px","position":"absolute"}}):_vm._e()],1)],1)}),1),_c('swiper',{staticClass:"swiper d-none d-md-block d-lg-none",style:({
            '--swiper-navigation-color': '#000000',
          }),attrs:{"options":_vm.swiperMediumOption}},_vm._l((_vm.examples),function(item,index){return _c('swiper-slide',{key:item.id},[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('examples-card',{attrs:{"item":item}}),(index !== 0)?_c('div',{staticStyle:{"border":"1px solid rgba(208, 208, 208, 0.4)","z-index":"80","height":"200px","position":"absolute"}}):_vm._e()],1)],1)}),1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }