<template>
  <div>
    <footer class="backgroundFooter">
      <div class="container">
        <div class="footer__inner">
          <div class="footer__item footer__item--copyright">
            <v-icon small color="#0d0d0d" style="margin-right: 10px">
              mdi-copyright
            </v-icon>
            2020-2023 IT-company BMAX
          </div>
          <div class="footer__item">
						<footerSocialList />
					</div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import footerSocialList from '@/components/Footer/footerSocialList.vue';
export default {
	components: {
		footerSocialList,
	}
};
</script>

<style scoped>
.backgroundFooter {
  background: white !important;
  background-image: none;
}
.footer__inner{
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.footer__item--copyright{
	display: flex; align-items: center;
	font-size: 16px;
	line-height: 18px;
	font-weight: 500;
	color: #0D0D0D;
}
@media(max-width: 830px){
	.footer__inner{
		flex-wrap: wrap-reverse;
		justify-content: center;
	}
	.footer__item{
		max-width: 100%;
		width: 100%;
		text-align: center;
	}
	.footer__item--copyright{
		justify-content: center;
	}
}
@media(max-width: 375px)
{
	.footer__item--copyright{
		font-size: 12px;
		line-height: 14px;
		font-weight: 500;
	}
}
</style>
